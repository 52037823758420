<template>
  <b-container class="py-bw-5 text-left" id="imprintsContainer">
    <b-row>
      <b-col cols="12">
        <h2 class="mb-4 h2-title">
          Impressum und Haftungshinweis
        </h2>
        <p class="p-bw">
          Barliner Workout<br>
          Inh. Denis Vitaliev<br>
          Heinrich-Heine-Str. 5<br>
          10179 Berlin<br>
          E-Mail: denis.vitaliev@barlinerworkout.com
          <br><br>
          Inhaber und vertretungsberechtigte Person: Denis Vitaliev
          <br><br>
          USt-IdNr.: DE 296131770
          <br><br>
          Verantwortlich für den Inhalt<br>
          gemäß § 55 Abs. 2 RStV:
          <br><br>
          Denis Vitaliev<br>
          Heinrich-Heine-Str. 5<br>
          10179 Berlin
          <br><br>
          E-Mail: denis.vitaliev@barlinerworkout.com
          <br><br>
          Haftungsausschluss für externe Links
          <br><br>
          Es wird keine Haftung für die Inhalte externer Links übernommen. Für die Inhalte der verlinkten Seiten sind
          ausschließlich deren Betreiber verantwortlich. Es wird hiermit ausdrücklich erklärt, dass zum Zeitpunkt der
          Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten erkennbar waren. Auf die aktuelle und
          zukünftige Gestaltung, die Inhalte oder die Urheberschaft der verlinkten Seiten besteht keinerlei Einfluss.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>
<style lang="scss" scoped>
#imprintsContainer {
  max-width: 980px;
  margin: 0 auto;
}
</style>
<script>
export default {
}
</script>
